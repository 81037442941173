import log from "loglevel";
import { toast } from "react-toastify";
import { toJS } from 'mobx';
import { RootStore } from "../root-store";
import { MSG } from "@config/messages";
import Api from "@api/premium-search";
import sockets from "@api/sockets";
import Utils from "@helpers/utils";
import {PremiumSearchFormData} from "../../types/PremiumSearchFormData";

const { makeAutoObservable } = require("mobx");


/**
 * Стор для работы с поиском Premium search
 */
class PremiumSearchStore {

    public rootStore: RootStore;

    public databases = [
        { value: "1", label: "Database 1" },
        { value: "2", label: "Database 2" }
    ];

    private formData: PremiumSearchFormData = {
        database: "1",
        type: "",
        firstname: "",
        lastname: "",
        address: "",
        city: "",
        state: "",
        dob: "",
        zip: "",
        ssn: "",
        batch: ""
    };

    // активная закладка (1 или 2)
    private _active_tab = 1;
    private _price: number = 0
    private _sid: number = 0;
    private _results: any = [];

    private _isHistory = true;
    private _toast: any;

    // region геттеры и сеттеры
    get database() {
        return this.formData.database;
    }
    set database(val) {
        this.formData.database = val;
    }
    get type() {
        return this.formData.type;
    }
    set type(val) {
        this.formData.type = val;
    }
    get firstname() {
        return this.formData.firstname;
    }
    set firstname(val) {
        this.formData.firstname = val;
    }
    get lastname() {
        return this.formData.lastname;
    }
    set lastname(val) {
        this.formData.lastname = val;
    }
    get address() {
        return this.formData.address;
    }
    set address(val) {
        this.formData.address = val;
    }
    get city() {
        return this.formData.city;
    }
    set city(val) {
        this.formData.city = val;
    }
    get state() {
        return this.formData.state;
    }
    set state(val) {
        this.formData.state = val;
    }
    get dob() {
        return this.formData.dob;
    }
    set dob(val) {
        this.formData.dob = val;
    }
    get zip() {
        return this.formData.zip;
    }
    set zip(val) {
        this.formData.zip = val;
    }
    get ssn() {
        return this.formData.ssn;
    }
    set ssn(val) {
        this.formData.ssn = val;
    }
    get batch() {
        return this.formData.batch;
    }
    set batch(val) {
        this.formData.batch = val;
    }

    get active_tab() {
        return this._active_tab;
    }
    set active_tab(val) {
        this._active_tab = val;
    }
    get price() {
        return this._price;
    }
    set price(val) {
        this._price = val;
    }
    get sid() {
        return this._sid;
    }
    set sid(val) {
        this._sid = val;
    }
    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }
    get isHistory() {
        return this._isHistory;
    }
    set isHistory(val) {
        this._isHistory = val;
    }
    get search_types() {
        log.debug("search_types", toJS(this.rootStore.configStore), toJS(this.formData));
        let result: any = [];
        if (this.rootStore.configStore.config) {
            for (let i of this.rootStore.configStore.config.finance.premium_search[this.formData.database]) {
                result.push({ value: i.name, label: `${i.name} - $${i.price}` });
            }
        }
        return result;
    }
    // endregion


    setField(field: string, value: string) {
        log.debug(`setField field:${field} value:${value}`);
        // @ts-ignore
        this.formData[field] = value;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        sockets.subscribe("premium_search", async (msg: any) => {
            log.debug("premium_search sockets message", msg);
            if (msg && msg.target === 'premium_search') {
                toast.info(MSG.MSG_SEARCH_RESULTS_READY + ": " + msg.data.result, {
                    autoClose: 5000
                });
                await this.rootStore.pageStore.loadUserData();
                await this.history();
            }
        });
    }

    clearForm() {
        log.debug("clearForm");
        for (let key of Object.keys(this.formData)) {
            // @ts-ignore
            this.formData[key] = "";
        }
    }

    clearBatchForm(valid_lines: number[]) {
        log.debug("clearBatchForm valid_lines", valid_lines);
        const lines = this.batch.split("\n");
        let res = [];
        for (let i = 0; i < lines.length; i++) {
            if (!valid_lines.includes(i)) {
                res.push(lines[i]);
            }
        }
        this.batch = res.join("\n");
    }

    async sendForm() {
        log.debug("sendForm", toJS(this.formData), toJS(this.database));
        this._toast = toast.info(MSG.MSG_LOADING);
        let data: any = {};
        if (this.active_tab === 1) {
            data.mode = 'single';
            Object.keys(this.formData).map(val => {
                if (val !== 'batch') {
                    // @ts-ignore
                    data[val] = "" + this.formData[val];
                }
            });
            data.ssn = data.ssn?.replace(/[^\d+]/g , "");
        } else {
            data = {
                mode: "batch",
                database: this.database,
                batch: this.batch
            }
        }
        try {
            const res = await Api.search(data);
            log.debug("PREMIUM_SEARCH", res);
            if (res.data.status === 'OK') {
                this.price = res.data.price;
                this.sid = res.data.sid;
                toast.dismiss(this._toast);
                await this.rootStore.pageStore.loadUserData();
                await this.history();
                if (data.mode === 'batch') {
                    this._toast = toast.info(Utils.stringFormat(MSG.MSG_ADD_TO_QUEUE, [res.data.data?.queue.length]));
                    if (res.data.data?.errors.length) {
                        toast.error(res.data.data?.errors?.join("\n"), {
                            autoClose: 10000,
                            pauseOnHover: true
                        });
                    }
                    this.clearBatchForm(res.data.data?.queue);
                }
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    async history(show_loading = false) {
        log.debug("history");
        try {
            if (show_loading) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            const res = await Api.history();
            log.debug("HISTORY", res.data.data);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                this.results = res.data.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    async searchAgain(request_id: number) {
        log.debug("searchAgain request_id:", request_id);
        const res = await Api.again(request_id);
        await this.rootStore.pageStore.loadUserData();
        log.debug("AGAIN", res);
        await this.history();
    }

}

export default PremiumSearchStore;